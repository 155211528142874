import { Container } from "@jsluna/grid"
import { Display3, Body1 } from "@jsluna/typography"
import Drafts from "../components/Drafts/Drafts"
import ReportIncident from "../components/ReportIncident"
import { useNavigate } from "react-router"

const DraftsPage = () => {

  const navigateTo = useNavigate()

  const createReport = async () => {
    navigateTo("/reporting")
  }

  return (
    <div className="s-drafts-dashboard ln-u-1/1">
      <ReportIncident onClick={createReport} />

      <Container className="ln-u-padding-bottom*4" size="xs">
        <div>
          <Display3 id="s-page-title" className="s-page-title ln-u-padding-left*2">
            My drafts
          </Display3>
        </div>
        <div>
          <Body1 id="s-page-label" className="s-page-label s-page-label-display ln-u-padding-left*2">
            Drafts are kept for 7 days, so that reports are accurate and we don&apos;t keep information longer than we need to.
          </Body1>
        </div>
      </Container>

      <Container className="s-drafts" size="xs">
        <Drafts />
      </Container>
    </div>
  )
}

export default DraftsPage