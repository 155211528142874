import { Key, useEffect } from 'react'
import { Container, GridWrapper } from '@jsluna/grid'
import { Card } from '@jsluna/card'
import { Pagination } from '@jsluna/pagination'
import Loading from '../Loading'
import useDrafts from '../../hooks/useDrafts'
import ErrorMessage from '../ErrorMessage'
import { IDraftDetails } from '../../types/Drafts/IDraftsTypes'
import DraftCard from '../Card/Cards/DraftCard'
import usePage from '../../hooks/usePage'
import { IPagination } from '../../types/Pagination/IPagination'

const Drafts = () => {
  const pageType = 'DraftsPage'
  const { page, handlePage } = usePage(pageType)
  let accidentsData: IDraftDetails[] | undefined = []
  let pagination: IPagination | undefined

  const [draftsResource, draftsLoader] = useDrafts()

  useEffect(() => {
    draftsLoader.current(page)
  }, [draftsLoader, page])

  if (draftsResource.hasLoaded && !draftsResource.hasError) {
    const accidentsReponse = draftsResource.data
    pagination = accidentsReponse?.metadata?.pagination
    accidentsData = accidentsReponse?.results
  }

  if (!draftsResource.hasLoaded && !draftsResource.hasError && !draftsResource.noContent)
    return (
      <Container size="xs">
        <Card id="s-loadingElement" className="ln-u-text-align-center">
          <Loading size="large" />
        </Card>
      </Container>
    )

    if (
      draftsResource.hasError ||
      !accidentsData ||
      accidentsData?.length <= 0 ||
      draftsResource.noContent
    )
      return (
        <Container size="xs">
          <ErrorMessage errorMessage="No Saved Drafts Were Found" />
        </Container>
      )

  return (
    <>
      <Container className="s-reported-accidents-list" size="xs">
        <GridWrapper
          id="s-reported-accident-cards"
          matrix
          gutterSize="sm"
          className="ln-o-grid--gutter-zero@max-mobile"
        >
          {accidentsData.map((draft: IDraftDetails, index: Key) => {
            if (!draft.isProcessed) {
              return <DraftCard key={index} draft={draft} />
            }
          })}
        </GridWrapper>
      </Container>

      {pagination && (
        <Pagination
          id="s-pagination-bar"
          className="ln-u-text-align-center ln-u-margin-ends*3"
          showFirstAndLast
          current={pagination.currentPage + 1}
          total={pagination.totalPages}
          onChange={(page: number, e: any) => {
            e.preventDefault()
            handlePage(pageType, page - 1)
          }}
        />
      )}
    </>
  )
}

export default Drafts
