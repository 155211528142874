import { format } from 'date-fns'

interface RelativeDateParams {
  dateTime?: Date | string
  type?: string
}

const getRelativeDate = ({ dateTime, type }: RelativeDateParams) => {
  
  if (!dateTime) return null;
  
  const inputDateTime = new Date(dateTime)
  switch (type) {
    case 'date':
      return format(inputDateTime, 'E d MMM yyyy')
    case 'time24':
      return format(inputDateTime, 'HH:mm')
    case 'time':
    return format(inputDateTime, 'h:mm a')
    default:
      return format(inputDateTime, "E d MMM yyyy 'at' h:mm a")
  }
}

export default getRelativeDate
