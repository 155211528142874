import { TextButton } from '@jsluna/button'
import { useComponentContext } from '../../ComponentContext'
import { ICancel } from '../../../../../types/ComponentTypes/Components'

function CancelComponent({ text }: ICancel) {
  const componentContext = useComponentContext()
  return (
    <TextButton
      id="s-reporting-cancel-button"
      fullWidth
      color="dark"
      onClick={componentContext.handleCancel}
    >
      {text}
    </TextButton>
  )
}

export default CancelComponent
