import { useComponentContext } from '../../ComponentContext'
import { IHeaders } from '../../../../../types/ComponentTypes/Components'
import { useEffect } from 'react'

function HeadersComponent({ metadata }: IHeaders) {
  const componentContext = useComponentContext()

  useEffect(() => {
    Object.entries(metadata).forEach(([key, value]) => {
        componentContext.handleUpdateMetadata({id: key, value: value})
    })
  }, [metadata])
  

  return null
}

export default HeadersComponent
