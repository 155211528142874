import { Body1, Display2 } from '@jsluna/typography'
import { IText } from '../../../../../types/ComponentTypes/Components'

function TextComponent({ label, value, style }: IText) {
  return (
    <>
      {label && (
        <Display2
          id="s-reporting-text-title"
          className={style === 'center' ? 's-text-align-center' : undefined}
          element="h6"
          label={label}
        >
          {label}
        </Display2>
      )}
      <Body1
        id="s-reporting-text-body"
        className={style === 'summary-text' ? 's-summary-text' : undefined}
        element="p"
      >
        {value}
      </Body1>
    </>
  )
}

export default TextComponent
