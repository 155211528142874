import { useComponentContext } from '../../ComponentContext'
import ComponentSwitch from '../..'

function HandleConditionals({ condition, expectedValues, currentValue }: any) {
  switch (condition) {
    case 'not_empty':
      return !!currentValue
    case 'empty':
      return !currentValue
    case 'equals':
      return expectedValues?.includes(currentValue)
    case 'not_equals':
      return !expectedValues?.includes(currentValue)
    default:
      return false
  }
}

function DependantComponent({
  components,
  key,
  dependsOn,
  condition,
  expectedValues
}: any) {
  const { handleGetAnswer } = useComponentContext()

  if (
    HandleConditionals({
      condition,
      expectedValues,
      currentValue:
        handleGetAnswer({ id: dependsOn }) !== undefined
          ? handleGetAnswer({ id: dependsOn })
          : ""
    })
  ) {
    return (
      <>
        {components.map((component: any) => (
          <ComponentSwitch {...component} key={`${key}-${component?.id}`} />
        ))}
      </>
    )
  }

  return null
}

export default DependantComponent
