import { TextAreaField } from '@jsluna/form'
import { useComponentContext } from '../../ComponentContext'
import { IMultiLine } from '../../../../../types/ComponentTypes/Components'
import { useEffect, useState } from 'react'

function MultiLineInputComponent({ label, info,  id, error, placeholder, answer, defaultValue, maxCharacterLimit }: IMultiLine) {
  
  const [value, setValue] = useState(defaultValue !== '' ? defaultValue : answer || '')
  const { handleUpdate } = useComponentContext()

  const handleChange = (e: any) => {
    const inputValue = e.target.value;
    if (maxCharacterLimit == 0 || inputValue.length <= maxCharacterLimit) {
      handleUpdate({ id, value: inputValue });
      setValue(inputValue);
    }
  }

  useEffect(() => {
    if (value) {
      handleChange({ target: { value: value } });
    }
  }, [value]);

  return (
    <div id="s-reporting-multiline-input">
      <TextAreaField
        name={id}
        label={label}
        info={info}
        error={error}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
      />
      {maxCharacterLimit > 0 && (
        <p
          className="ln-u-margin-ends ln-u-text-align-right ln-u-color-grey-60 ln-u-font-weight-regular"
          aria-live="polite"
          aria-atomic="true"
        >
          {value.length}
          <span className="ln-u-visually-hidden"> out of </span>
          <span aria-hidden="true"> / </span>
          {maxCharacterLimit}
        </p>
      )}
    </div>
  )
}

export default MultiLineInputComponent
