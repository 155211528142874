import { Routes, Route, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react'
import {
  Container,
  Modal,
  SiteLayout,
  Footer
} from '@jsluna/react';
import ReportingPage from './pages/ReportingPage';
import { AppContext } from './context/AppContext'
import { InteractionType } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication
} from '@azure/msal-react'
import './scss/main.scss';
import useCurrentStore from './hooks/useCurrentStore';
import { IStore } from './types/IStore';
import Loading from './components/Loading';
import useStoreLoader from './hooks/useStoreLoader';
import StorePage from './pages/StorePage';
import NavHeader from './components/Header/NavHeader';
import IncidentsPage from './pages/IncidentsPage';
import DraftsPage from './pages/DraftsPage';

const App = () => {
  const context = useContext(AppContext)

  useMsalAuthentication(InteractionType.Redirect, {
    scopes: [context?.apiScope ?? '']
  })

  const location = useLocation()
  useEffect(() => {
    if (
      context?.store !== undefined &&
      store.storeCode !== context!.store!.storeCode
    )
      setStore(context!.store!)
  }, [location])

  const currentStore = useCurrentStore()

  const [store, setStore] = useState(currentStore)
  const [storeResource, loadStore] = useStoreLoader()

  useEffect(() => {
    loadStore.current(currentStore.storeCode)
  }, [context, currentStore.storeCode, loadStore])

  if (context && storeResource.data && context.store === undefined) {
    const storeDetails = storeResource.data
    const store: IStore = {
      storeCode: storeDetails.code,
      storeName: storeDetails.name,
      inScope: storeDetails ? true : false
    }
    context.store = store
    setStore(store)
  }

  if (!storeResource.hasLoaded && !storeResource.hasError) return <Loading />


  if ((context && context.store !== undefined && !context.store.inScope) || storeResource.hasError) {
    return (
      <AuthenticatedTemplate>
        <NavHeader />
        <Container size="md" soft>
            <Routes>
              <Route path="*" element={<StorePage />} />
            </Routes>
        </Container>
      </AuthenticatedTemplate>
    )
  }

  if (currentStore.storeCode == '') return <Loading message='Please wait' />

  return (
    <>
      <AuthenticatedTemplate>
        <SiteLayout>
          <NavHeader />
          <Container size="md" soft>
            <main id="main-content" className="ln-u-push-top-md">
              <Pages />
            </main>
          </Container>
          <Footer>&copy; Sainsbury&apos;s Supermarkets Ltd</Footer>
        </SiteLayout>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Modal restrictClose open={true}>
          <h4> Redirecting... </h4>
        </Modal>
      </UnauthenticatedTemplate>
    </>

  );
}

const Pages = () => {
  return (
    <Routes>
      <Route path="/stores" element={<StorePage />} />
      <Route path="/reporting" element={<ReportingPage />} />
      <Route path="/drafts" element={<DraftsPage />} />
      <Route path="/" element={<IncidentsPage />} />
    </Routes>
  )
};

export default App;