import { Body1 } from '@jsluna/typography'
import { IIncidentNumber } from '../../../types/AccidentReportOverview/IAccidentReportOverview'

const IncidentNumber = ({ incidentNumber } : IIncidentNumber) => {
  return (
    <div>
      <Body1
        className="s-accidentNumber ln-u-color-grey"
        id="s-accidentNumber"
      >
        SIMS Ref: {incidentNumber}
      </Body1>
    </div>
  )
}

export default IncidentNumber