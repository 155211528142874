import { Display2 } from '@jsluna/typography'
import { ITitle } from '../../../types'

const Title = ({ text }: ITitle) => {
  return (
    <div>
      <Display2 id="s-card-title">{text}</Display2>
    </div>
  )
}

export default Title
