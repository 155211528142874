import { useApiClient } from '../context/AppContext'
import IResourceLoader from '../types/IResourceLoader'
import useCallbackRef from './useCallbackRef'
import useResourceLoader from './useResourceLoader'
import { IDraftsData } from '../types/Drafts/IDraftsTypes'
import draftsApi from '../api/draftsApi'
import { ILoadDrafts } from '../types/Drafts/ILoadDrafts'
import { FetchDrafts } from '../types/Drafts/FetchDrafts'

const useDrafts = (): [IResourceLoader<IDraftsData>, ILoadDrafts] => {
  const apiClient = useApiClient()

  const resource = useResourceLoader<IDraftsData>(
    'Unable to load saved drafts'
  )

  const loader = useCallbackRef<FetchDrafts>(
    async (page: number): Promise<IDraftsData> => {
      return resource.load(async () =>
        draftsApi.GetDrafts(apiClient, page)
      )
    }
  )

  return [resource, loader]
}

export default useDrafts
