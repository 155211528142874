import { TextInputField } from '@jsluna/form'
import { OutlinedButton } from '@jsluna/button'
import { IColleagueLookup } from '../../../../../types/ComponentTypes/Components'
import { useEffect, useState } from 'react'
import { useApiClient } from '../../../../../context/AppContext'
import { useComponentContext } from '../../ComponentContext'
import { ProgressIndicator, ProgressBar } from '@jsluna/progress'
import { Container } from '@jsluna/grid'
import { ErrorCircle } from '@jsluna/icons'
import colleagueApi from '../../../../../api/colleagueApi'

function ColleagueLookupComponent({
  id,
  error,
  searchLabel,
  nameLabel,
  searchPlaceholder,
  defaultValue
}: IColleagueLookup) {
  const apiClient = useApiClient()
  const [colleagueName, setColleagueName] = useState(
    defaultValue ? defaultValue : ''
  )
  const [noColleagueFound, setNoColleagueFound] = useState(false)
  const [colleagueId, setColleagueId] = useState('')
  const [loading, setLoading] = useState(false)
  const { handleUpdate } = useComponentContext()

  useEffect(() => {
    handleUpdate({
      id: id,
      value: colleagueName
    })
  }, [colleagueName])

  const handleIdChange = (e: any) => {
    setColleagueId(e.target.value)
  }

  const handleButtonClick = async () => {
    if (colleagueId) {
      setLoading(true)
      const colleagueLookup = await colleagueApi.GetColleague(
        apiClient,
        colleagueId
      )

      if (colleagueLookup.colleagueID) {
        setNoColleagueFound(false)
        setColleagueName(
          `${colleagueLookup.person.firstName} ${colleagueLookup.person.lastName} - ${colleagueLookup.colleagueID}`
        )
      }

      if (colleagueLookup.status == '204') {
        setNoColleagueFound(true)
        setLoading(false)
        return
      }
    }
    setLoading(false)
  }

  const handleNameChange = () => {
    setColleagueName('')
  }

  return (
    <div>
      <Container className="ln-u-padding-bottom*3">
        <TextInputField
          name={'colleague-lookup-search'}
          label={searchLabel}
          error={
            error
              ? 'Search for colleague by their id or reselect last question'
              : null
          }
          onChange={handleIdChange}
          value={colleagueId || ''}
          placeholder={searchPlaceholder}
        />
        <OutlinedButton
          fullWidth
          onClick={() => {
            handleButtonClick()
          }}
        >
          {'Search Colleague Id'}
        </OutlinedButton>
      </Container>

      {noColleagueFound && (
        <div className="ln-u-padding-bottom*3">
          <ErrorCircle
            id="s-search-error"
            className="s-error-icon ln-u-display ln-u-margin-bottom*1/2"
          />
          <span className="s-error-address ln-u-padding">
            {'A colleague with that id can not be found'}
          </span>
        </div>
      )}

      {colleagueName && (
        <TextInputField
          name={'colleague-lookup-name'}
          label={nameLabel}
          error={error}
          onChange={handleNameChange}
          value={colleagueName || ''}
        />
      )}

      {loading && (
        <ProgressIndicator loading preventFocus>
          <ProgressBar
            className="ln-u-push-bottom-lg"
            title="Regular sized progress indicator demo"
          />
        </ProgressIndicator>
      )}
    </div>
  )
}

export default ColleagueLookupComponent
