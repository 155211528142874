import React, { useContext } from 'react'

const ComponentContext = React.createContext<any>({})

export class ComponentContextData {
  answers: any
  handleNext: any
  handleUpdate: any
  handleUpdateMetadata: any
  handleResetMetadata: any
  handleGetAnswer: any
  handleGetAnswers: any
  handleCancel: any
  handleBack: any
  enableNext: any
  disableNext: any
  isNextDisabled: any
  handleSave: any
  isSaved: any
  constructor(
    availableComponents: any,
    answers: any,
    handleNext: any,
    handleUpdate: any,
    handleUpdateMetadata: any,
    handleResetMetadata: any,
    handleGetAnswer: any,
    handleGetAnswers: any,
    handleCancel: any,
    handleBack: any,
    enableNext: any,
    disableNext: any,
    isNextDisabled: any,
    handleSave: any,
    isSaved: any,
  ) {
    this.availableComponents = availableComponents
    this.answers = answers
    this.handleNext = handleNext
    this.handleUpdate = handleUpdate
    this.handleUpdateMetadata = handleUpdateMetadata
    this.handleResetMetadata = handleResetMetadata
    this.handleGetAnswer = handleGetAnswer
    this.handleGetAnswers = handleGetAnswers
    this.handleCancel = handleCancel
    this.handleBack = handleBack
    this.enableNext = enableNext
    this.disableNext = disableNext
    this.isNextDisabled = isNextDisabled
    this.handleSave = handleSave
    this.isSaved = isSaved
  }
  availableComponents: any
}

export function ComponentContextProvider({
  children,
  availableComponents,
  answers,
  handleNext,
  handleUpdate,
  handleUpdateMetadata,
  handleResetMetadata,
  handleGetAnswer,
  handleGetAnswers,
  handleCancel,
  handleBack,
  enableNext,
  disableNext,
  isNextDisabled,
  handleSave,
  isSaved,
}: any) {
  return (
    <ComponentContext.Provider
      value={
        new ComponentContextData(
          availableComponents,
          answers,
          handleNext,
          handleUpdate,
          handleUpdateMetadata,
          handleResetMetadata,
          handleGetAnswer,
          handleGetAnswers,
          handleCancel,
          handleBack,
          enableNext,
          disableNext,
          isNextDisabled,
          handleSave,
          isSaved,
        )
      }
    >
      {children}
    </ComponentContext.Provider>
  )
}

export const useComponentContext = () => useContext(ComponentContext)