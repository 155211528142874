import webApi from './webApi'
import { ApiClient } from './ApiClient'
import { IIncidentsData } from '../types/Incidents/IIncidentTypes'

const GetReports = async (
  apiClient: ApiClient,
  storeCode: string,
  page: number
): Promise<IIncidentsData> => {
  const response = await webApi.get<IIncidentsData>(apiClient, {
    url: `/api/reports`,
    params: { storeCode: storeCode, pageNumber: page }
  })
  return response
}

const accidentsApi = {
  GetReports
}

export default accidentsApi
