import webApi from './webApi'
import { ApiClient } from './ApiClient'
import { IDraftsData } from '../types/Drafts/IDraftsTypes'

const GetDrafts = async (
  apiClient: ApiClient,
  page: number
): Promise<IDraftsData> => {
  const response = await webApi.get<IDraftsData>(apiClient, {
    url: `/api/drafts`,
    params: { pageNumber: page }
  })
  return response
}

const draftsApi = {
    GetDrafts
}

export default draftsApi
