import { AppContext } from '../context/AppContext'
import IResourceLoader from '../types/IResourceLoader'
import { ILoadStoresList } from '../types/ILoadStoresList'
import { FetchStoresList } from '../types/FetchStoresList'
import storesApi from '../api/storesApi'
import { useContext } from 'react'
import { IDetails } from '../types/IStoreDetails'
import useResourceLoader from './useResourceLoader'
import useCallbackRef from './useCallbackRef'

const useSearchStoresListLoader = (): [IResourceLoader<IDetails[]>, ILoadStoresList] => {
  const context = useContext(AppContext)

  const fetchStores = async (code?: string, ...params: any) => {
    if (code !== undefined && code !== '') {
      const store = await storesApi.getStore(context!, code!).then(r => r)
      return [store]
    } else if (params?.length > 0 && params[0] !== '') {
      const stores = await storesApi
        .getStores(context!, params?.[0])
        .then(r => r)
      return stores
    }
    return []
  }

  const resource = useResourceLoader<IDetails[]>('Unable to load stores list')

  const loader = useCallbackRef<FetchStoresList>(
    (code?: string, ...params: any) =>
      resource.load(async () => fetchStores(code, ...params))
  )

  return [resource, loader]
}

export default useSearchStoresListLoader