import { Card } from '@jsluna/card'
import ComponentSwitch from '../..'
import { ISemanticBox } from '../../../../../types/ComponentTypes/Components'

function SemanticBoxComponent({ components, key, style }: ISemanticBox) {
  return (
    <div>
      {
        {
          information: (
            <Card className="s-information-box s-semantic-box">
              {components.map((component: any ) => (
                <ComponentSwitch
                  {...component}
                  key={`${key}-${component.id}`}
                />
              ))}
            </Card>
          ),
          warning: (
            <Card className="s-warning-box s-semantic-box">
              {components.map((component: any) => (
                <ComponentSwitch
                  {...component}
                  key={`${key}-${component.id}`}
                />
              ))}
            </Card>
          ),
          error: (
            <Card className="s-error-box s-semantic-box">
              {components.map((component: any) => (
                <ComponentSwitch
                  {...component}
                  key={`${key}-${component.id}`}
                />
              ))}
            </Card>
          ),
          success: (
            <Card className="s-success-box s-semantic-box">
              {components.map((component: any) => (
                <ComponentSwitch
                  {...component}
                  key={`${key}-${component.id}`}
                />
              ))}
            </Card>
          ),
          summary: (
            <Card className="s-summary-box s-semantic-box">
              {components.map((component: any) => (
                <ComponentSwitch
                  {...component}
                  key={`${key}-${component.id}`}
                />
              ))}
            </Card>
          ),
        }[style]
      }
    </div>
  )
}

export default SemanticBoxComponent