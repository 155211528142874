import { FC, ReactNode, createContext, useContext } from 'react'
import { useMsal } from '@azure/msal-react'
import { ApiClient } from '../api/ApiClient'
import { IStore } from '../types/IStore'
import { IDetails } from '../types/IStoreDetails'
import { CurrentPage } from '../types/CurrentPage/CurrentPage'

export type AppContextType = {
  apiClient: ApiClient
  apiScope: string
  store?: IStore | undefined
  storeList?: IDetails[] | undefined
  page: CurrentPage
  sessionReset: boolean
}

export const AppContext = createContext<AppContextType | null>(null)

type AppProviderProps = {
  apiScope: string
  apiBaseUrl: string
  store?: IStore | undefined
  storeList?: IDetails[] | undefined
  children: ReactNode
  page: CurrentPage
  sessionReset: boolean
}

export const AppProvider: FC<AppProviderProps> = ({
  apiScope,
  apiBaseUrl,
  store,
  page,
  sessionReset,
  children
}) => {
  const { instance } = useMsal()
  const apiClient = new ApiClient(instance, apiScope, apiBaseUrl)

  return (
    <AppContext.Provider value={{ apiClient, apiScope, store, page, sessionReset }}>
      {' '}
      {children}{' '}
    </AppContext.Provider>
  )
}

export function useApiClient() {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useApiClient must be used within an AppProvider')
  }

  return context.apiClient
}