import { Display3 } from '@jsluna/typography'
import { Container } from '@jsluna/grid'
import { ITitle } from '../../../../../types/ComponentTypes/Components'

function TitleComponent({ value }: ITitle) {
  if (value === 'Problem info') {
    return (
      <Container>
        <Display3 id="s-reporting-page-title" element="h2">
          {value}
        </Display3>
      </Container>
    )
  }

  return (
    <Container soft>
      <Display3 id="s-reporting-page-title" element="h2">
        {value}
      </Display3>
    </Container>
  )
}

export default TitleComponent
