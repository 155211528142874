import { ErrorCircle, InfoCircle, Like } from '@jsluna/icons'
import { IIcon } from '../../../../../types/ComponentTypes/Components'

function IconComponent({ value }: IIcon) {
  switch (value) {
    case 'ErrorIcon':
      return (
        <div id="s-reporting-icon" className="s-red-icon ln-u-text-align-center">
          <ErrorCircle
            id="s-errorCircle"
            className="s-error-icon ln-u-color-red ln-u-display-7 ln-u-push-bottom"
          />
        </div>
      )
    case 'RedInfoIcon':
      return (
        <div
          id="s-reporting-icon"
          className="s-red-info-icon ln-u-text-align-center"
        >
          <InfoCircle
            id="s-infoCicrle"
            className="s-red-info-icon ln-u-color-red ln-u-display-7 ln-u-push-bottom"
          />
        </div>
      )
    case 'BlueInfoIcon':
      return (
        <div
          id="s-reporting-icon"
          className="s-blue-info-icon ln-u-text-align-center"
        >
          <InfoCircle
            id="s-infoCicrle"
            className="s-blue-info-icon ln-u-color-blue ln-u-display-7 ln-u-push-bottom"
          />
        </div>
      )
    case 'LikeIcon':
      return (
        <div id="s-reporting-icon" className="s-like-icon ln-u-text-align-center">
          <Like
            if="s-likeIcon"
            className="s-like-icon ln-u-color-green ln-u-display-7 ln-u-push-bottom"
          />
        </div>
      )
    default:
      return <div> </div>
  }
}

export default IconComponent
