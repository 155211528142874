import { Body1, Display1 } from '@jsluna/typography'
import { GridItem } from '@jsluna/react'

function MajorInjuries() {

    return(
        <>
        <GridItem className="ln-u-padding-top*3 ln-u-padding-bottom*1">
        <Display1>
            Amputation
        </Display1><br />
        <Body1 >
            The removal of a limb due to a physical trauma.
        </Body1>
        </GridItem>
        <GridItem className="ln-u-padding-top*3 ln-u-padding-bottom*3">
        <Display1>
            Burns
        </Display1><br />
        <Body1 >
            Burns that cover more than 10% of the body area are classified as a major injury. No matter how deep the burn is {'('}first-degree {'('}outer-layer{')'}, second-degree {'('}inner-layer{')'}, third-degree {'('}muscle and bones{')'}{')'}.
        </Body1>
        </GridItem>
        <GridItem className="ln-u-padding-bottom*3">
        <Display1>
            Crush
        </Display1><br />
        <Body1>
            When a body part is forcefully compressed, squeezed or trapped between objects causing a break, damage or distorting of the body.
        </Body1>
        </GridItem>
        <GridItem className="ln-u-padding-bottom*3">
        <Display1>
            Unconsciousness
        </Display1><br />
        <Body1>
            A state where a person doesnt respond to verbal communication or physical contact and may appear asleep.
        </Body1>
        </GridItem>
        </>

    )
}

export default MajorInjuries