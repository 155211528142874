import { Container } from '@jsluna/grid'
import { IconButton, OutlinedButton } from '@jsluna/button'
import { ArrowLeft } from '@jsluna/icons'
import { useComponentContext } from '../../ComponentContext'
import { IBack } from '../../../../../types/ComponentTypes/Components'

function BackComponent({ text, browserBack }: IBack) {
  const componentContext = useComponentContext()
  function handleReturn() {
    if (browserBack) {
      window.history.back();
    } else {
      componentContext.handleBack();
    }
  }

  if (text === '') {
    return (
      <IconButton
        color="dark"
        variant="text"
        label="Back"
        onClick={handleReturn}
        id="s-back-button"
      >
        <ArrowLeft size="small" />
      </IconButton>
    )
  }

  return (
    <Container className="ln-u-push-ends">
      <OutlinedButton
        id="s-big-back-button"
        fullWidth
        onClick={handleReturn}
      >
        {text}
      </OutlinedButton>
    </Container>
  )
}

export default BackComponent
