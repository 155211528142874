import { IList } from '../../../../../types/ComponentTypes/Components'
import { Label } from '@jsluna/form'

function ListComponent({ label, listValues }: IList) {
  return (
    <div id="s-reporting-list">
      <Label htmlFor=""> {label} </Label>
      <ul className="s-reporting-list">
        {listValues.map((list, index) => (
          <li id="s-reporting list" key={index}>
            {list}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ListComponent
