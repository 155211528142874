import { FC } from "react"
import useCurrentStore from "../../hooks/useCurrentStore"
import useCurrentStoresList from "../../hooks/useCurrentStoresList"
import StoreDisplay from "./StoreDisplay"
import Loading from "../Loading"

interface StoreListProps {
  selectStore: (store: any) => void
  queryCode?: string
}

const StoreList: FC<StoreListProps> = ({ selectStore, queryCode }) => {
  const currentStore = useCurrentStore()
  const stores = useCurrentStoresList()
  const selectNewStore = (storeSelected: any) => {
    selectStore(storeSelected)
  }

  let tmpStores = stores

  if (
    queryCode &&
    queryCode.length < 10 &&
    (!tmpStores || tmpStores.length === 0)
  ) {
    tmpStores = [{ code: queryCode! }]
  }
  if (tmpStores === undefined) return <Loading message="Loading stores..." />
  if (!tmpStores || tmpStores.length === 0)
    return <span>No stores found matching your criteria.</span>

  return (
    <div id='s-storeDisplay'>
      {tmpStores.map((storeDetail, index) => (
        <StoreDisplay
          storeDetail={storeDetail}
          index={index}
          currentStore={currentStore}
          changeStore={selectNewStore}
          key={index}
        />
      ))}
    </div>
  )
}

StoreList.defaultProps = {
  queryCode: undefined
}

export default StoreList
