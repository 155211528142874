import { Button, FilledButton, OutlinedButton } from '@jsluna/button'
import { Container } from '@jsluna/grid'
import { ProgressIndicator, ProgressSpinner, ProgressMessage } from '@jsluna/progress'
import { useComponentContext } from '../../ComponentContext'
import { INext } from '../../../../../types/ComponentTypes/Components'
import { useState } from 'react'
import ComponentSwitch from '../..'

function NextComponent({ id, text, style, response, promptConfirm, reference }: INext) {
  const buttonStyle = style ? style : 'filled'
  const buttonText = text ? text : 'Next'
  const componentContext = useComponentContext()

  const [showPrompt, setShowPrompt] = useState(false)
  const [handleResult, setHandleResult] = useState<((result: boolean) => void)>(() => {})

  const updateResponse = () => {

    if (reference) {
      componentContext.handleUpdateMetadata({ id: reference, value: 'triggered by user.' })
    }

    if (response) {
      componentContext.handleUpdate({id: id, value: response})
    }
  }

  const triggerNext = (e: any) => {
    if (promptConfirm) {
      setShowPrompt(true)

      const handleResult = async (result: boolean) => {
        setShowPrompt(false)

        if (result) {
          updateResponse()
          await componentContext.handleNext(e)
        }
      }
      componentContext.handleResetMetadata()
      setHandleResult(() => handleResult)
    } else {
      componentContext.handleNext(e)
    }
  }

  return (
    <div>
      {showPrompt ? (
        <ComponentSwitch {...promptConfirm} handleResult={handleResult} />
      ) : null}
      <Container className="ln-u-push-ends">
        {
          {
            filled: (
              <FilledButton
                id={id}
                fullWidth
                onClick={triggerNext}
                onMouseDown={updateResponse}
                disabled={componentContext.isNextDisabled()}
              >
                {componentContext.isNextDisabled() && (
                  <ProgressIndicator
                    loading
                    preventFocus
                    message={<ProgressMessage>{buttonText}</ProgressMessage>}
                  >
                    <ProgressSpinner
                      color="light"
                      size="small"
                      className="ln-u-margin-right"
                    />
                  </ProgressIndicator>
                )}
                {!componentContext.isNextDisabled() && buttonText}
              </FilledButton>
            ),
            outlined: (
              <OutlinedButton
                id={id}
                fullWidth
                onClick={triggerNext}
                onMouseDown={updateResponse}
                disabled={componentContext.isNextDisabled()}
              >
                {componentContext.isNextDisabled() && (
                  <ProgressIndicator
                    loading
                    preventFocus
                    message={<ProgressMessage>{buttonText}</ProgressMessage>}
                  >
                    <ProgressSpinner
                      color="light"
                      size="small"
                      className="ln-u-margin-right"
                    />
                  </ProgressIndicator>
                )}
                {!componentContext.isNextDisabled() && buttonText}
              </OutlinedButton>
            ),
            outlined_dark: (
              <Button
                id={id}
                variant="outlined"
                circle={false}
                disabled={false}
                hard={false}
                element="button"
                color="dark"
                onClick={triggerNext}
                onMouseDown={updateResponse}
                fullWidth
              >
                {componentContext.isNextDisabled() && (
                  <ProgressIndicator
                    loading
                    preventFocus
                    message={<ProgressMessage>{buttonText}</ProgressMessage>}
                  >
                    <ProgressSpinner
                      color="light"
                      size="small"
                      className="ln-u-margin-right"
                    />
                  </ProgressIndicator>
                )}
                {!componentContext.isNextDisabled() && buttonText}
              </Button>
            ),
            text_dark: (
              <Button
                id={id}
                variant="text"
                circle={false}
                disabled={false}
                hard={false}
                element="button"
                color="dark"
                onClick={triggerNext}
                onMouseDown={updateResponse}
                fullWidth
              >
                {componentContext.isNextDisabled() && (
                  <ProgressIndicator
                    loading
                    preventFocus
                    message={<ProgressMessage>{buttonText}</ProgressMessage>}
                  >
                    <ProgressSpinner
                      color="light"
                      size="small"
                      className="ln-u-margin-right"
                    />
                  </ProgressIndicator>
                )}
                {!componentContext.isNextDisabled() && buttonText}
              </Button>
            )
          }[buttonStyle]
        }
      </Container>
    </div>
  )
}

export default NextComponent
