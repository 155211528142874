import { Body1 } from '@jsluna/typography'
import getRelativeDate from '../../../utils/getRelativeDate'
import { IReportedByDescription } from '../../../types'

const ReportedByDescription = ({ reportedBy, dateReported, timeReported }: IReportedByDescription) => {
  return (
    <div id="s-reported-by-description">
      <Body1
        className="ln-u-color-grey"
        >
        Reported by {reportedBy}, on {getRelativeDate({dateTime: dateReported, type:"date"})} at {getRelativeDate({dateTime: timeReported, type:"time"})}
      </Body1>
    </div>
  )
}

export default ReportedByDescription
