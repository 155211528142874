import { ProgressIndicator, ProgressSpinner, ProgressMessage } from '@jsluna/progress'
import { ISave } from '../../../../../types/ComponentTypes/Components'
import { Button, FilledButton, OutlinedButton } from '@jsluna/button'
import { useComponentContext } from '../../ComponentContext'
import { Container } from '@jsluna/grid'

function SaveComponent({ id, text, style, reference }: ISave) {
  const buttonStyle = style ? style : 'filled'
  const buttonText = text ? text : 'Save'
  const componentContext = useComponentContext()

  const updateResponse = () => {
    if (reference) {
      componentContext.handleUpdateMetadata({ id: reference, value: 'triggered by user.' })
    }
  }

  const triggerNext = () => {
    if (!componentContext.isSaved()) {
      componentContext.handleSave()
    }
  }

  const renderButton = () => {
    const commonProps = { id, fullWidth: true, onClick: triggerNext, onMouseDown: updateResponse, disabled: componentContext.isNextDisabled() || componentContext.isSaved(),
      children: (
        <>
          {componentContext.isNextDisabled() && (
            <ProgressIndicator loading preventFocus message={<ProgressMessage>{buttonText}</ProgressMessage>}>
              <ProgressSpinner color="light" size="small" className="ln-u-margin-right"/>
            </ProgressIndicator>
          )}
          {!componentContext.isNextDisabled() && buttonText}
        </>
      )
    }

    switch (buttonStyle) {
      case 'filled':
        return <FilledButton {...commonProps} />
      case 'outlined':
        return <OutlinedButton {...commonProps} />
      case 'outlined_dark':
        return (
          <Button
            {...commonProps}
            variant="outlined"
            circle={false}
            disabled={componentContext.isSaved()}
            hard={false}
            element="button"
            color="dark"
          />
        )
      case 'text_dark':
        return (
          <Button
            {...commonProps}
            variant="text"
            circle={false}
            disabled={componentContext.isSaved()}
            hard={false}
            element="button"
            color="dark"
          />
        )
      default:
        return null
    }
  }

  return (
    <div>
      <Container className="ln-u-push-ends">
        {renderButton()}
      </Container>
    </div>
  )
}

export default SaveComponent