import { AppContextType } from '../context/AppContext'
import { IDetails } from '../types/IStoreDetails'
import webApi from './webApi'


const getStore = async (
  context: AppContextType,
  code: string
): Promise<IDetails> => {
  const storeCode = code !== '' ? code : ''

  const response = await webApi.get<IDetails>(context.apiClient, {
    url: '/api/storelocator/store',
    params: { storeCode: storeCode }
  })

  return response
}

const getStores = async (
  context: AppContextType,
  query: any | undefined = ''
): Promise<IDetails[]> => {
  const response = await webApi.get<IDetails[]>(context.apiClient, {
    url: '/api/storelocator/stores',
    params: { query: query }
  })

  return response
}

const storesApi = {
  getStore,
  getStores
}

export default storesApi
