import { TextInputField } from '@jsluna/form'
import { useComponentContext } from '../../ComponentContext'
import { IInput } from '../../../../../types/ComponentTypes/Components'
import { useState, useEffect } from 'react'

function InputComponent({ label, id, error, placeholder, info, answer, defaultValue, maxCharacterLimit }: IInput) {
  const [value, setValue] = useState(answer ? answer : defaultValue || '')
  const { handleUpdate } = useComponentContext()

  useEffect(() => {
    if (value) {
      handleUpdate({ id, value: value });
    }
  }, []);

  const handleChange = (e: any) => {
    const inputValue = e.target.value;
    if (maxCharacterLimit == 0 || inputValue.length <= maxCharacterLimit) {
      handleUpdate({ id, value: inputValue });
      setValue(inputValue);
    }
  }

  return (

    <div>
      <TextInputField
        name={id}
        label={label}
        info={info}
        error={error}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
      />
      {maxCharacterLimit != 0 && (
        <p
          className="ln-u-margin-ends ln-u-text-align-right ln-u-color-grey-60 ln-u-font-weight-regular"
          aria-live="polite"
          aria-atomic="true"
        >
          {value.length}
          <span aria-hidden="true"> / </span>
          {maxCharacterLimit}
        </p>
      )}
    </div>
  )
}

export default InputComponent
