import { Container } from "@jsluna/grid"
import { Display3 } from "@jsluna/typography"
import Incidents from "../components/Incidents/Incidents"
import ReportIncident from "../components/ReportIncident"
import { useNavigate } from "react-router"

const IncidentsPage = () => {

  const navigateTo = useNavigate()

  const createReport = async() => {
    navigateTo("/reporting")
  }

  const pageTitle = () => {
    return (
      <Container className="ln-u-padding-bottom*4" size="xs">
        <div>
          <Display3 id="s-page-title" className="s-page-title ln-u-padding-left*2">
            Reported Incidents
          </Display3>
        </div>
      </Container>
    )
  }

  return (
    <div className="s-reported-incidents-dashboard ln-u-1/1">
      
      <ReportIncident onClick={createReport}/>

      {pageTitle()}

      <Container className="s-reported-incidents" size="xs">
        <Incidents />
      </Container>
    </div>
  )
}

export default IncidentsPage