import { Link } from 'react-router-dom'

const ChangeStore = () => {
  return (
    <>
      <Link to="/stores">Change site</Link>
    </>
  )
}

export default ChangeStore
