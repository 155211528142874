import { useNavigate } from 'react-router'
import { Card } from '@jsluna/card'
import { IDraft } from '../../../types/Drafts/IDraftsTypes'
import Title from '../Components/Title'
import IncidentDescription from '../Components/IncidentDescription'
import { useState } from 'react'
import PromptComponent from '../../Reporting/ComponentSwitch/Components/PromptComponent'
import IconComponent from '../../Reporting/ComponentSwitch/Components/IconComponent'
import { Body1 } from '@jsluna/typography'

const DraftCard = ({ draft }: IDraft) => {

  const [showPrompt, setShowPrompt] = useState(false)

  const responses = JSON.parse(draft.session.responseStorage)

  const incidentCategory = responses?.safety?.report?.incident_category_label ?? null
  const incidentDate = responses?.safety?.report?.incident_date ?? null
  const incidentTime = responses?.safety?.report?.incident_time ?? null
  const incidentLocation = responses?.safety?.report?.incident_location_label ?? null
  const incidentStore = responses?.safety?.report?.incident_store_label ?? null

  const navigateTo = useNavigate()

  function continueSession() {
    sessionStorage.setItem('draftId', draft.id )
    navigateTo('/reporting')
  }

  function handlePromptResponse (response: boolean) {
    setShowPrompt(false)
    if (response) {
      continueSession()
    }
  }

  return (
    <>
    {showPrompt ? <PromptComponent 
      title={'Continue draft'} 
      body={'Do you want to continue where you left off?'} 
      text={'Continue'} 
      icon={<IconComponent value={'BlueInfoIcon'}/>} 
      classification=''
      handleResult={handlePromptResponse} />
    : null}
    
    {draft.type ? 
    <Card className="s-reported-accident-card rln-c-card ln-c-card--soft ln-u-margin-bottom*2 ln-u-margin-left s-card-clickable" onClick={() => setShowPrompt(true)}>
      <Title
        text={draft.type.replace('_', ' ') + ' - ' + (incidentCategory ?? 'Unknown category')  } 
      />
      <IncidentDescription
        dateOfIncident={incidentDate ? new Date(incidentDate) : null}
        timeOfIncident={incidentTime ?? null}
        location={incidentLocation ? incidentLocation : null }
        store={incidentStore ? incidentStore : null}
        type={"draft"}
      />
    </Card> 
    : 
    
    <Card className="s-reported-accident-card rln-c-card ln-c-card--soft ln-u-margin-bottom*2 ln-u-margin-left s-card-clickable s-card-faded" onClick={() => setShowPrompt(true)}>
      <Title
        text={'Not yet started'}
      />
      <Body1 className="ln-u-color-dark">
        Click here to start this draft</Body1>
    </Card>
    }
    </>
  )
}

export default DraftCard
