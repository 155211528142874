import { useRef, useLayoutEffect } from 'react'
import { ICallbackRef } from '../types/ICallbackRef'

const useCallbackRef = <T>(callback: T): ICallbackRef<T> => {
  const callbackRef = useRef(callback)
  useLayoutEffect(() => {
    callbackRef.current = callback
  }, [callback])
  return callbackRef
}

export default useCallbackRef
