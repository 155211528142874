import { Container } from '@jsluna/react'
import { Heading4 } from '@jsluna/typography'
import { ErrorCircle } from '@jsluna/icons'

const ErrorMessage = ({ errorMessage }: any) => {
  return (
    <div>
      <Container className="ln-u-padding*2">
        <ErrorCircle
          id="s-errorCircle"
          className="s-error-icon ln-u-padding-bottom ln-u-color-grey-80 ln-u-display-4"
        />
        <Heading4
          className="ln-u-padding ln-u-padding-top*4"
          style={{ display: 'inline' }}
        >
          {errorMessage}
        </Heading4>
      </Container>
    </div>
  )
}

export default ErrorMessage
