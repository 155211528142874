import { ApiClient } from './ApiClient'
import webApi from './webApi'
import { IReporting } from '../types/IReporting'
import { IImageUploadResponse } from '../types/IImageUploadResponse'

export const getQuestions = async (
  apiClient: ApiClient,
  response: any
): Promise<IReporting> => {
  return await webApi.post<IReporting>(apiClient, {
    url: `/api/form/create`,
    params: [],
    body: JSON.stringify(response),
    contentType: 'application/json'
  })
}

export const fileUpload = async (
  apiClient: ApiClient,
  { ...files } = {},
  images: any
) => {
  let totalSize = 0
  const formData = new FormData()
  Object.entries(files).map(([, value]: [any, any]) => {
    totalSize += value.size
    formData.append(value.name, value)
  })
  if (totalSize > 20971520) {
    return {
      error: true,
      message: 'Image size exceeds 20MB, please upload smaller image(s)'
    }
  }

  if (
    (images && images.length >= 20) ||
    (files && Object.entries(files).length > 20)
  ) {
    return {
      error: true,
      message: 'You can only upload a maximum of 20 images'
    }
  }

  const response = await webApi.post<IImageUploadResponse[]>(apiClient, {
    url: '/api/image/upload',
    params: [],
    body: formData,
    contentType: null
  })

  if (response != null || response != undefined) {
    return response
  }

  return {
    error: true,
    message: 'An error has occurred please try again'
  }
}

export const fileRemoval = async (
  apiClient: ApiClient,
  uploadReference: any
) => {
  const response = await webApi.remove(apiClient, {
    url: `/api/image/remove`,
    params: { reference: uploadReference }
  })

  if (response.status === 200) {
    return response
  }

  return {
    error: true,
    message: 'Failed to remove file'
  }
}

const reportingApi = {
  getQuestions
}

export const getImagesURLs = async (apiClient: ApiClient, images: string) => {
  const response = await webApi.post(apiClient, {
    url: `/api/image/get`,
    params: [],
    body: images,
    contentType: 'application/json'
  })
  return response
}

export default reportingApi
