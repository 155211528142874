import { useUser } from '../../../hooks'

const NavCurrentUser = () => {
  const user = useUser()

  const formattedName = user.name
    .toLowerCase()
    .replace('.', ' ')
    .split(' ')
    .map((name: string) => name.charAt(0).toUpperCase() + name.slice(1))
    .join(' ')

  return <span className="cc-nav-fix">{formattedName}</span>
}

export default NavCurrentUser
