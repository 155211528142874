import { Button, FilledButton, OutlinedButton } from '@jsluna/button'
import { ILinkButton } from '../../../../../types/ComponentTypes/Components'
import { Container } from '@jsluna/grid'
import { useNavigate } from "react-router"

function LinkButtonComponent({ value, link, buttonType }: ILinkButton) {
  const button = buttonType !== '' ? buttonType : 'filled'
  const navigateTo = useNavigate()
  return (
    <div className="s-phone-button ln-u-push-bottom">
      <Container className="ln-u-push-ends">
        {
          {
            filled: (
              <a onClick={() => navigateTo(link)}>
                <FilledButton fullWidth>{value}</FilledButton>
              </a>
            ),
            outlined: (
              <a onClick={() => navigateTo(link)}>
                <OutlinedButton fullWidth>{value}</OutlinedButton>
              </a>
            ),
            outlined_dark: (
              <a onClick={() => navigateTo(link)}>
                <Button variant="outlined" color="dark" fullWidth>
                  {value}
                </Button>
              </a>
            )
          }[button]
        }
      </Container>
    </div>
  )
}

export default LinkButtonComponent
