import { Display1, Body1 } from '@jsluna/typography'
import { IHyperlink } from '../../../../../types/ComponentTypes/Components'

function HyperlinkComponent({ beginTextValue, endTextValue, hyperLinkValue, link, id }: IHyperlink) {

  return (
    <>
    { 
    beginTextValue && endTextValue ?  (
      <span>
        <Body1 element="p">
          {beginTextValue}
            <b>
              <a id={id} target="_blank" rel="noreferrer" className='s-hyperlink' href={link}>
                {hyperLinkValue}
              </a>
            </b>
          {endTextValue}
        </Body1>
      </span>
      ):     
      <span className="s-hyperlink">
        <a id={id} href={link}>
          <Display1>{hyperLinkValue}</Display1>
        </a>
      </span> 
    }
    </>
  )
}

export default HyperlinkComponent
