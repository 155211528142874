import { IAddressDetails } from '../types'
import webApi from './webApi'
import { ApiClient } from './ApiClient'

const GetAddress = async (
  apiClient: ApiClient,
  postCode: string
): Promise<IAddressDetails[] | any> => {
  const response = await webApi.get<IAddressDetails[]>(apiClient, {
    url: `/api/address`,
    params: { postCode: postCode }
  })
  return response
}

const addressApi = {
  GetAddress
}

export default addressApi
