import { Label } from '@jsluna/form'
import { ILabel } from '../../../../../types/ComponentTypes/Components'

function LabelComponent({ value, id }: ILabel) {
  return (
    <div>
      <Label id="s-formbuilder-label" htmlFor={id}>{value}</Label>
    </div>
  )
}

export default LabelComponent
