import { useApiClient } from '../context/AppContext'
import IResourceLoader from '../types/IResourceLoader'
import useCallbackRef from './useCallbackRef'
import useResourceLoader from './useResourceLoader'
import { FetchIncidents, ILoadIncidents } from '../types'
import accidentsApi from '../api/accidentsApi'
import { IIncidentsData } from '../types/Incidents/IIncidentTypes'

const useIncidents = (): [IResourceLoader<IIncidentsData>, ILoadIncidents] => {
  const apiClient = useApiClient()

  const resource = useResourceLoader<IIncidentsData>(
    'Unable to load reported accidents'
  )

  const loader = useCallbackRef<FetchIncidents>(
    async (storeCode: string, page: number): Promise<IIncidentsData> => {
      return resource.load(async () =>
        accidentsApi.GetReports(apiClient, storeCode, page)
      )
    }
  )

  return [resource, loader]
}

export default useIncidents
