export const getBaseUrl = (endpoint: string, environmentUrl: string) => {
  if (environmentUrl !== '') return environmentUrl
  else if (endpoint.includes('api/storelocator/'))
    return 'https://localhost:7079'
  else if (endpoint.includes('api/form/')) return 'https://localhost:7079'
  else if (endpoint.includes('api/image/')) return 'https://localhost:7079'
  else if (endpoint.includes('api/session')) return 'https://localhost:7079'
  else if (endpoint.includes('api/address')) return 'https://localhost:7079'
  else if (endpoint.includes('api/colleague')) return 'https://localhost:7079'

}
