import { Container } from '@jsluna/grid'
import { Button } from '@jsluna/button'
import { useState } from 'react';
import PromptComponent from '../Reporting/ComponentSwitch/Components/PromptComponent';
import IconComponent from '../Reporting/ComponentSwitch/Components/IconComponent';

interface ReportIncidentProps {
  onClick: () => void;
}

const ReportIncident = ({ onClick }: ReportIncidentProps) => {

  const [showPrompt, setShowPrompt] = useState(false)
  
  const handleOnClick = () => {
    if (sessionStorage.getItem('draftId')) {
      sessionStorage.removeItem('draftId')
    }
    setShowPrompt(true)
  }

  function handlePromptResponse (response: boolean) {
    setShowPrompt(false)
    if (response) {
      onClick()
    }
  }

  return (
    <>
      {showPrompt ? <PromptComponent 
        title={'Report a new incident'} 
        body={'Would you like to create a new incident report?'} 
        text={'Yes'} 
        icon={<IconComponent value={'BlueInfoIcon'}/>}
        classification=''
        handleResult={handlePromptResponse} />
      : null}

      <Container className="ln-u-padding-ends*3" size="xs">
        <Button
          id="s-report-safety-accident"
          className="ln-u-bg-color-white report-accident-button report-accident-button-mobile"
          variant="outlined"
          disabled={false}
          element="button"
          onClick={handleOnClick}
          >
          Report a safety incident
        </Button>
      </Container>
    </>
  );
};

export default ReportIncident;