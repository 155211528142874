import { RadioButtonField } from '@jsluna/form'
import { useComponentContext } from '../../ComponentContext'
import { IRadioButton } from '../../../../../types/ComponentTypes/Components'
import { useEffect, useState } from 'react'

function RadioButtonComponent({
  label,
  info,
  options,
  id,
  error,
  answer,
  listType,
  outlined,
  fullWidth,
  defaultValue
}: IRadioButton) {
  const [value, setValue] = useState(defaultValue !== '' ? defaultValue : answer || '')
  const { handleUpdate } = useComponentContext()
  const handleChange = (e: any) => {
    handleUpdate({ id, value: e.target.value })
    setValue(e.target.value)
  }

  useEffect(() => {
    if (value) {
      handleChange({ target: { value: value } });
    }
  }, [value]);

  return (
    <div id="s-reporting-radiobutton">
      <RadioButtonField
        label={label}
        info={info}
        name={id}
        options={options}
        className="s-reporting-radiobutton"
        error={error}
        onChange={handleChange}
        value={value}
        listType={listType}
        outlined={outlined}
        fullWidth={fullWidth}
      />
    </div>
  )
}

export default RadioButtonComponent
