import { useState, useEffect } from 'react'
import { AutocompleteField } from '@jsluna/autocomplete'
import { useComponentContext } from '../../ComponentContext'
import { IDropdown } from '../../../../../types/ComponentTypes/Components'

function AutocompleteDropdownComponent({ label, options, placeholder, id, error, answer, defaultValue }: IDropdown) {
  const [autoCompleteDropdownValue , setAutoCompleteDropdownValue] = useState(answer ? options.find((op: any) => op.value === answer)?.label || '': options.find((op: any) => op.value === defaultValue)?.label || '')
  const { handleUpdate } = useComponentContext()
  const [descriptionText, setDescriptionText] = useState('')

  const handleChange = (e: any) => {
    setAutoCompleteDropdownValue(e.target.value)
  }

  const handleStoreSelect = (e: any) => {
    if (e !== null) {
      setDescriptionText(options.find((el: any) => el.value === e.value)?.description,)
      handleUpdate({ id, value: e.value })
      setAutoCompleteDropdownValue(options.find((op: any) => op.value === e.value)?.label)
    } else {
      handleUpdate({ id, value: '' })
      setAutoCompleteDropdownValue('')
      setDescriptionText('')
    }
  }

  useEffect(() => {
    if (!answer && defaultValue) {
      handleUpdate({ id, value: defaultValue});
    }
  }, []);

  return (
    
    <div id="s-reporting-autocomplete-dropdown" tabIndex={-1}>
        <AutocompleteField
          name={id}
          placeholder={placeholder}
          label={label}
          options={options}
          onChange={handleChange}
          onSelect={handleStoreSelect}
          error={error}
          value={autoCompleteDropdownValue || ''}
          role="search"
        />
      
      <p id="s-option-description">{descriptionText}</p>
    </div>
  )
}

export default AutocompleteDropdownComponent
