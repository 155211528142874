import { Body1 } from '@jsluna/typography'
import { IInvestigation } from '../../../types'

const Investigation = ({ status }: IInvestigation) => {
  return (
    <div>
      <Body1
        id="s-severity"
        className={'ln-u-font-weight-bold'}
        style={{
          color: status?.toLowerCase()?.includes('investigation required')
            ? '#d50000'
            : '#404040'
        }}
      >
        {status}
      </Body1>
    </div>
  )
}

export default Investigation
