import { Button } from '@jsluna/button'
import { Modal, GridItem } from '@jsluna/react'
import { Body1, Display3 } from '@jsluna/typography'
import { IPrompt } from '../../../../../types/ComponentTypes/Components'
import IconComponent from '../IconComponent'
import MajorInjuries from './MajorInjuries'

function PromptComponent({
  title,
  body,
  text,
  icon,
  classification,
  handleResult
}: IPrompt) {
  return (
    <Modal small alert hideCloseButton open={true} headingId="dialog-modal">
      <GridItem className="ln-u-hard">
        {icon ? <IconComponent {...icon} /> : null}
      </GridItem>
      <GridItem className="ln-u-hard ln-u-text-align-center">
        <Display3>{title}</Display3>
      </GridItem>
      <GridItem
        className={
          classification === 'major-injuries'
            ? 'ln-u-margin-left*2 ln-u-padding-right*3'
            : 'ln-u-hard ln-u-text-align-center'
        }
      >
        <Body1>{body}</Body1>
      </GridItem>
      {classification === 'major-injuries' && (
        <GridItem>
          <MajorInjuries />
        </GridItem>
      )}
      <Button
        circle={false}
        disabled={false}
        hard={false}
        element="button"
        variant="filled"
        fullWidth
        className="s-continue-button ln-u-margin-ends*2"
        onClick={() => handleResult(true)}
      >
        {text}
      </Button>
      <Button
        circle={false}
        disabled={false}
        hard={false}
        element="button"
        variant="outlined"
        fullWidth
        className="s-reset-button"
        onClick={() => handleResult(false)}
      >
        Back
      </Button>
    </Modal>
  )
}

export default PromptComponent
