const e = encodeURIComponent

const queryParams = params =>{

let data =  
  Object.keys(params)
    .map(k => `${e(k)}=${e(params[k])}`)
    .join('&')

return data
}

export default ( params) => queryParams(params)