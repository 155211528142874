import { Card } from '@jsluna/card'
import ComponentSwitch from '../..'
import { ICard } from '../../../../../types/ComponentTypes/Components'

function CardComponent({ components, key }: ICard) {
  return (
    <Card id="s-reporting-card" className="ln-u-border" soft="true">
      {components?.map((component: any) => (
        <ComponentSwitch {...component} key={`${key}-${component?.id}`} />
      ))}
    </Card>
  )
}

export default CardComponent
