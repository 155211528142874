import TitleComponent from './Components/TitleComponent'
import CardComponent from './Components/CardComponent'
import NextComponent from './Components/NextComponent'
import DropdownComponent from './Components/DropdownComponent'
import TextComponent from './Components/TextComponent'
import HyperlinkComponent from './Components/HyperlinkComponent'
import LinkButtonComponent from './Components/LinkButtonComponent'
import InputComponent from './Components/InputComponent'
import CancelComponent from './Components/CancelComponent'
import IconComponent from './Components/IconComponent'
import MultiLineInputComponent from './Components/MultiLineInputComponent'
import UploadComponent from './Components/UploadComponent'
import BackComponent from './Components/BackComponent'
import RadioButtonComponent from './Components/RadioButtonComponent'
import ProductInfoComponent from './Components/ProductInfoComponent'
import SemanticBoxComponent from './Components/SemanticBoxComponent'
import DependantComponent from './Components/DependantComponent'
import DisabledComponent from './Components/DisabledComponent'
import LabelComponent from './Components/LabelComponent'
import CheckboxListComponent from './Components/CheckboxListComponent'
import DateTimePickerComponent from './Components/DateTimePickerComponent'
import SummaryComponent from './Components/SummaryComponent'
import HeadersComponent from './Components/HeadersComponent'
import AutocompleteDropdownComponent from './Components/AutocompleteDropdownComponent'
import MultiAutocompleteComponent from './Components/MultiAutocomplete'
import ListComponent from './Components/ListComponent'
import PostcodeLookupComponent from './Components/PostcodeLookupComponent'
import PromptComponent from './Components/PromptComponent'
import SaveComponent from './Components/SaveComponent'
import ColleagueLookupComponent from './Components/ColleagueLookupComponent'

const components = {
  title: TitleComponent,
  card: CardComponent,
  next: NextComponent,
  dropdown: DropdownComponent,
  text: TextComponent,
  hyperlink: HyperlinkComponent,
  link_button: LinkButtonComponent,
  input: InputComponent,
  cancel: CancelComponent,
  back: BackComponent,
  icon: IconComponent,
  multi_line_input: MultiLineInputComponent,
  upload: UploadComponent,
  radiobutton: RadioButtonComponent,
  productInfo: ProductInfoComponent,
  semanticBox: SemanticBoxComponent,
  dependant: DependantComponent,
  label: LabelComponent,
  disabled: DisabledComponent,
  checkboxList: CheckboxListComponent,
  dateTimePicker: DateTimePickerComponent,
  summary : SummaryComponent,
  headers : HeadersComponent,
  autocompleteDropdown: AutocompleteDropdownComponent,
  multiAutocomplete: MultiAutocompleteComponent,
  list : ListComponent,
  postcodeLookup : PostcodeLookupComponent,
  prompt : PromptComponent,
  save: SaveComponent,
  colleagueLookup: ColleagueLookupComponent
}

// This is a function so it can be mocked.
export default function getAvailableComponents() {
  return components
}
