const page = {
  IncidentsPage: null,
  DraftsPage: null
}

const resetData = (context: any) => {
  context!.page = page
  sessionStorage.removeItem('IncidentsPage')
  sessionStorage.removeItem('DraftsPage')
}

export default resetData
