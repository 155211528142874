import webApi from './webApi'
import { ApiClient } from './ApiClient'
import { IColleagueDetails } from '../types/Colleague/IColleagueTypes'

const GetColleague = async (
  apiClient: ApiClient,
  id: string
): Promise<IColleagueDetails | any> => {
  const response = await webApi.get<IColleagueDetails>(apiClient, {
    url: `/api/colleague`,
    params: { id: id }
  })
  return response
}

const colleagueApi = {
  GetColleague
}

export default colleagueApi
