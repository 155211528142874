import { useComponentContext } from '../../ComponentContext'
import ComponentSwitch from '../..'

function HandleConditionals({ condition, expectedValue, currentValues}: any) {

  switch (condition) {
    case 'not_empty':    
      return currentValues.some((val: any) => (val !== undefined && val !== null) && val !== '')
    case 'empty':
      return currentValues.some((val: any) => (val === undefined || val === null))
    case 'equals':
      return currentValues.some((val: any) => val === expectedValue)
    case 'not_equals':
      return currentValues.some((val: any) => val !== expectedValue)
    default:
      return false
  }
}

function DisabledComponent({
  components,
  key,
  dependsOn,
  condition,
  expectedValue,
}: any) {
  const { handleGetAnswers } = useComponentContext()

  return (
    <fieldset
      disabled= 
        {HandleConditionals({
        condition,
        expectedValue,
        currentValues: handleGetAnswers({ids: dependsOn})
      })}   
    >
      {components.map((component: any) => (
        <ComponentSwitch {...component} key={`${key}-${component.id}`} />
      ))}
    </fieldset>
  )
}

export default DisabledComponent
