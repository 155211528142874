import { NavLink, useLocation} from 'react-router-dom'
import {
  ChangeStore,
  NavCurrentUser,
  NavStoreName,
  SainsburysLogo
} from './nav'
import SignOutButton from '../auth/SignOutButton'
import {
  Header as LunaHeader,
  HeaderLogo,
  HeaderNav,
  HeaderMainBar,
  HeaderGlobalItem
} from '@jsluna/header'
import HeaderGlobalBar from './CustomHeaderGlobalBar'
import { Caption } from '@jsluna/typography'

const NavHeader = () => {
  const { pathname } = useLocation()
  return (
    <LunaHeader divided hasGlobalBar hasMainBar>
      <HeaderGlobalBar
        label="Group navigation"
        element="nav"
        className={undefined}
        size={undefined}
      >
        <HeaderGlobalItem>
          <SainsburysLogo />
        </HeaderGlobalItem>
        <HeaderGlobalItem align="right" drawer="true" tabbar="md">
          <span id="s-user-store">
            <NavCurrentUser /> in <NavStoreName />
          </span>
        </HeaderGlobalItem>
        <HeaderGlobalItem>
          <span id="s-nav-link-stores">
            <ChangeStore />
          </span>
        </HeaderGlobalItem>
        <HeaderGlobalItem>
          <span id="s-nav-link-signOut">
            <SignOutButton />
          </span>
        </HeaderGlobalItem>
      </HeaderGlobalBar>

      <HeaderMainBar size="nav">
        <HeaderLogo>
          <NavLink id="s-logo" to="/" style={{ textDecoration: 'none' }}>
            Safety Assist
          </NavLink>
        </HeaderLogo>
        <HeaderNav openDropdownOnHover drawer tabBar="sm" label="Main">
          <NavLink id="s-nav-link-accidents" to="/" active={pathname === '/'}>
            Report incident
          </NavLink>
          <NavLink id="s-nav-link-accidents" to="/drafts" active={pathname === '/drafts'}>
            My Drafts
          </NavLink>
        </HeaderNav>

        <HeaderNav align="center" drawer className="s-u-hidden@max-nav">
          <Caption>
            <NavCurrentUser /> &nbsp;in&nbsp; <NavStoreName />
          </Caption>
          <Caption>
            <ChangeStore />
          </Caption>
          <Caption>
            <SignOutButton />
          </Caption>
        </HeaderNav>
      </HeaderMainBar>
    </LunaHeader>
  )
}
export default NavHeader