import { useEffect, useState } from 'react'
import { SelectField } from '@jsluna/form'
import { useComponentContext } from '../../ComponentContext'
import { IDropdown } from '../../../../../types/ComponentTypes/Components'

function DropdownComponent({ label, options, placeholder, id, error, answer, defaultValue }: IDropdown) {
  const [value, setValue] = useState(defaultValue !== '' ? defaultValue : answer || '')
  const { handleUpdate } = useComponentContext()
  const [descriptionText, setDescriptionText] = useState('')
  const handleChange = (e: any) => {
    e.target.value !== null ?
    setDescriptionText(
      options.find((el: any) => el.value === e.target.value)?.description,
    ) : setDescriptionText('')
    handleUpdate({ id, value: e.target.value })
    setValue(e.target.value)
  }

  useEffect(() => {
    if (value) {
      handleChange({ target: { value: value } });
    }
  }, [value]);

  return (
    
    <div id="s-reporting-dropdown" tabIndex={-1}>
      <SelectField
        name={id}
        label={label}
        className="s-reporting-dropdown"
        options={options}
        error={error}
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
      />
      <p id="s-option-description">{descriptionText}</p>
    </div>
  )
}

export default DropdownComponent
