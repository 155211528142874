import { useContext } from "react"
import { AppContext } from "../context/AppContext"
import IResourceLoader from "../types/IResourceLoader"
import { IDetails } from "../types/IStoreDetails"
import useCallbackRef from "./useCallbackRef"
import useResourceLoader from "./useResourceLoader"
import { ILoadStoresList } from "../types/ILoadStoresList"
import { FetchStoresList } from "../types/FetchStoresList"
import storesApi from "../api/storesApi"

const useStoresListLoader = (): [
  IResourceLoader<IDetails[]>,
  ILoadStoresList
] => {
  const context = useContext(AppContext)

  const resource = useResourceLoader<IDetails[]>('Unable to load stores list')

  const loader = useCallbackRef<FetchStoresList>(() =>
    resource.load(async () => storesApi.getStores(context!))
  )

  return [resource, loader]
}

export default useStoresListLoader
