import { Card } from '@jsluna/card'
import { Container } from '@jsluna/react'
import { ISummary } from '../../../../../types/ComponentTypes/Components'
import ComponentSwitch from '../..'

interface PersonData {
  person_injured: string
  person_observed: string
}
const personType = 'person_type'

function SummaryComponent({
  data,
  components,
  controls,
  placeholder,
  error
}: ISummary) {
  const ErrorMessage = ({ message }: any) => {
    return (
      <Container className="ln-c-field-info ln-c-field-info--error ln-u-padding-bottom*3">
        {message}
      </Container>
    )
  }

  const updateTypeOfPerson = ({
    person_injured,
    person_observed
  }: PersonData) => {
    if (person_injured == 'yes') return `Injured`
    if (person_observed == 'yes') return 'Observer'
    return ''
  }

  if (data == null || data == "") {
    return (
      <>
        <Card className="s-summary-box s-semantic-box">
          {placeholder}
        </Card>
        {error ? <ErrorMessage message={error} /> : null }
      </>
    )
  }

  const summaryData = JSON.parse(data)

  return (
    <div>
      {Object.keys(summaryData).map(key => {
        const summaryItem = summaryData[key]
        return (
          <>
            <Card className="s-summary-box s-semantic-box">
              {components.map((component: any, index: number) => {
                return (
                  <ComponentSwitch
                    {...component}
                    value={
                      component.id != personType
                        ? summaryItem[component.id]
                        : `${updateTypeOfPerson(summaryItem)} ${summaryItem[component.id]}`
                    }
                    key={component.id + key + index}
                  />
                )
              })}
              {controls.map((control: any, index: number) => {
                return <ComponentSwitch {...control} response={key} key={control.id + key + index} />
              })}
            </Card>
          </>
        )
      })}
      {error ? <ErrorMessage message={error} /> : null }
    </div>
  )
}

export default SummaryComponent
