import { Container } from '@jsluna/grid'
import { GridWrapper } from '@jsluna/grid'
import { Key, useEffect } from 'react'
import useCurrentStore from '../../hooks/useCurrentStore'
import Loading from '../Loading'
import { Card } from '@jsluna/card'
import ErrorMessage from '../ErrorMessage'
import useIncidents from '../../hooks/useIncidents'
import IncidentCard from '../Card/Cards/IncidentCard'
import usePage from '../../hooks/usePage'
import { Pagination } from '@jsluna/pagination'
import { IIncidentDetails } from '../../types'
import { IPagination } from '../../types/Pagination/IPagination'

const Incidents = () => {
  const storeCode = useCurrentStore().storeCode
  const pageType = 'IncidentsPage'
  const { page, handlePage } = usePage(pageType)
  let incidentsData: IIncidentDetails[] | undefined = []
  let pagination: IPagination | undefined

  const [incidentsResource, incidentsLoader] = useIncidents()

  useEffect(() => {
    incidentsLoader.current(storeCode, page)
  }, [incidentsLoader, storeCode, page])

  if (incidentsResource.hasLoaded && !incidentsResource.hasError) {
    const incidentsResponse = incidentsResource.data
    pagination = incidentsResponse?.metadata?.pagination
    incidentsData = incidentsResponse?.results
  }

  if (!incidentsResource.hasLoaded && !incidentsResource.hasError && !incidentsResource.noContent)
    return (
      <Container size="xs">
        <Card id="s-loadingElement" className="ln-u-text-align-center">
          <Loading size="large" />
        </Card>
      </Container>
    )

  if (
    incidentsResource.hasError ||
    !incidentsData ||
    incidentsData?.length <= 0 ||
    incidentsResource.noContent
  )
    return (
      <Container size="xs">
        <ErrorMessage errorMessage="No Reported Incidents Were Found" />
      </Container>
    )

  return (
    <>
      <Container className="s-reported-accidents-list" size="xs">
        <GridWrapper
          id="s-reported-accident-cards"
          matrix
          gutterSize="sm"
          className="ln-o-grid--gutter-zero@max-mobile"
        >
          {incidentsData.map((incident: IIncidentDetails, index: Key) => (
            <IncidentCard key={index} incident={incident} />
          ))}
        </GridWrapper>
      </Container>

      {pagination && (
        <Pagination
          id="s-pagination-bar"
          className="ln-u-text-align-center ln-u-margin-ends*3"
          showFirstAndLast
          current={pagination.currentPage + 1}
          total={pagination.totalPages}
          onChange={(page: number, e: any) => {
            e.preventDefault()
            handlePage(pageType, page - 1)
          }}
        />
      )}
    </>
  )
}

export default Incidents
