import { Body1 } from '@jsluna/typography'
import getRelativeDate from '../../../utils/getRelativeDate'
import { IIncidentDescription } from '../../../types'

const IncidentDescription = ({ dateOfIncident, timeOfIncident, location, store, type }: IIncidentDescription) => {
  
  const date = getRelativeDate({dateTime:dateOfIncident ?? undefined, type:'date'})
  const time = type == "incident" ? getRelativeDate({dateTime:timeOfIncident ?? undefined, type:'time24'}) : timeOfIncident

  return (
    <>
    <div id="s-reported-accident-card-description">
      <Body1
        className="ln-u-color-dark"
      >
        { date ? ("on "+ date) : ''} {timeOfIncident ? (" at " + time) : ''}
      </Body1>
    </div>
    
    <div>
      <Body1
        className="ln-u-font-weight-bold ln-u-color-dark"
      >
        {location ? ("in " + location) : ''} {store ? (" at " + store) : ''}
      </Body1>
    </div>
    </>
  )
}

export default IncidentDescription