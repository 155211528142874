import { CheckboxField } from '@jsluna/form'
import { useComponentContext } from '../../ComponentContext'
import { ICheckbox } from '../../../../../types/ComponentTypes/Components'

function CheckboxListComponent({
  label,
  info,
  options,
  id,
  error,
  answer,
  listType,
  outlined,
  fullWidth,
}: ICheckbox) {
  const selectedValues = answer !== null ? answer.split(',') : []
  const retainedValues: any = []
  const { handleUpdate } = useComponentContext()
  const handleChange = (e: any) => {
    if (selectedValues.includes(e.target.value)) {
      selectedValues.splice(selectedValues.indexOf(e.target.value), 1)
    } else {
      selectedValues.push(e.target.value)
    }
    handleUpdate({ id, value: `${selectedValues}` })
  }
  options.forEach((option: any) => {
    retainedValues.push({
      value: option.value,
      label: option.label,
      defaultChecked: selectedValues.includes(option.value)
    })
  })
  
  return (
    <div id="s-reporting-checkboxlist">
      <CheckboxField
        label={label}
        name={id}
        info={info}
        options={retainedValues}
        className="s-reporting-checkboxlist"
        error={error}
        onChange={handleChange}
        listType={listType || 'bare'}
        outlined={outlined}
        fullWidth={fullWidth}
      />
    </div>
  )
}

export default CheckboxListComponent
