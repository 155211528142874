import { Card } from '@jsluna/card'
import {
  Title,
  IncidentNumber,
  Investigation,
  IncidentDescription,
  ReportedByDescription
} from '../Components'
import { IIncident } from '../../../types'

const IncidentCard = ({ incident }: IIncident) => {
  return (
    <Card className="s-reported-accident-card rln-c-card ln-c-card--soft ln-u-margin-bottom*2 ln-u-margin-left">
      <Title text={incident.category} />
      <IncidentNumber incidentNumber={incident.recordId} />
      <Investigation status={incident.status} />
      <IncidentDescription
        dateOfIncident={incident.dateOfIncident}
        timeOfIncident={incident.timeOfIncident}
        location={incident.location}
        store={incident.store}
        type={"incident"}
      />
      <ReportedByDescription
        reportedBy={incident.reportedBy}
        dateReported={incident.dateReported}
        timeReported={incident.timeReported}
      />
    </Card>
  )
}

export default IncidentCard
