import { useContext, useState } from 'react'
import { AppContext } from '../context/AppContext'
import { CurrentPage } from '../types/CurrentPage/CurrentPage'

const usePage = (pageType: keyof CurrentPage) => {
  const context = useContext(AppContext)

  const handlePage = (pageType: keyof CurrentPage, pageNumber: number) => {
    if (context && pageNumber >= 0) {
      sessionStorage.setItem(pageType, pageNumber.toString())
      context.page[pageType] = pageNumber
      setPage(pageNumber)
    }
  }

  const setInitialData = (pageType: keyof CurrentPage): number => {
    const context = useContext(AppContext)
    if (context?.page[pageType] === null) {
      const pageTypeSessionValue = sessionStorage.getItem(pageType)
      if (pageTypeSessionValue) {
        context.page[pageType] = Number(pageTypeSessionValue)
        return Number(pageTypeSessionValue)
      } else {
        context.page[pageType] = 0
        return 0
      }
    }
    return Number(context?.page[pageType])
  }

  const [page, setPage] = useState<number>(setInitialData(pageType))

  return {
    page,
    handlePage
  }
}

export default usePage
