import { FilledButton } from '@jsluna/button'
import { Container } from '@jsluna/grid'

function TryAgainButton({ tryAgain }: any) {
  return <FilledButton onClick={tryAgain}>Try Again</FilledButton>
}

function Error({ message, tryAgain }: any ) {
  return (
    <Container className="s-page-push" id="s-error-page" soft>
      {message}
      {tryAgain && <TryAgainButton tryAgain={tryAgain} />}
    </Container>
  )
}

export default Error
