import { SelectField, TextInputField } from '@jsluna/form'
import { OutlinedButton } from '@jsluna/button'
import { IAddressLookup } from '../../../../../types/ComponentTypes/Components'
import { useEffect, useState } from 'react'
import addressApi from '../../../../../api/addressApi'
import { useApiClient } from '../../../../../context/AppContext'
import { useComponentContext } from '../../ComponentContext'
import { ProgressIndicator, ProgressBar } from '@jsluna/progress'
import ComponentSwitch from '../..'
import { Container } from '@jsluna/grid'
import { ErrorCircle } from '@jsluna/icons'
import { IAddressDetails } from '../../../../../types'

function PostcodeLookupComponent({
  key,
  id,
  error,
  defaultValue,
  firstLine,
  secondLine,
  thirdLine,
  city,
  county,
  postcode,
  country
}: IAddressLookup) {

  const apiClient = useApiClient()
  const { handleUpdate } = useComponentContext()

  const [value, setValue] = useState(defaultValue ? JSON.parse(defaultValue) : null)
  const [postcodeField, setPostcodeField] = useState('')

  const [manualAddress, setManualAddress] = useState((defaultValue || firstLine.defaultValue || city.defaultValue || postcode.defaultValue) ? true : false)
  const [loading, setLoading] = useState(false)
  const [noAddresses, setNoAddresses] = useState(false)
  const [addressOptions, setAddressOptions] = useState<{label: string, value: string}[]>([])

  useEffect(() => {
    handleUpdate({ id: id, value: value == undefined ? value : JSON.stringify(value)})
  }, [value])

  const handleChange = (e: any) => {
    setPostcodeField(e.target.value)
  }

  const handleManualButton = (state: boolean) => {
    setManualAddress(state)
    setNoAddresses(state)
    setValue(state ? {} : undefined)
  }

  const handleButtonClick = async () => {
    if (postcodeField) {
      setAddressOptions([])
      setLoading(true)

      const addresses = await addressApi.GetAddress(apiClient, postcodeField)

      if (addresses.status == '204') {
        setNoAddresses(true)
        setLoading(false)
        return
      }

      if (addresses.length > 0) {
        setAddressOptions(addresses.map((address : IAddressDetails | any) => ({
          label: [address.line1, address.line2, address.line3, address.city, address.postcode].filter(n=>n).join(", "),
          value: JSON.stringify(address),
        })))
        setNoAddresses(false)
      }

      setLoading(false)
    }
  }

  const handleDropDownChange = (e: any) => {
    setValue(JSON.parse(e.target.value))
    setAddressOptions([])
    setManualAddress(true)
    setPostcodeField('')
  }

  return (
    <div id="s-reporting-dropdown" tabIndex={-1}>

      {!manualAddress && (
        <>
          <Container className="ln-u-padding-bottom*3">
            <TextInputField
              name={'postcode-lookup-input'}
              label={'Postcode'}
              onChange={handleChange}
              value={postcodeField}
              placeholder={'Please enter postcode'}
            />
            <OutlinedButton
              fullWidth
              onClick={() => {
                handleButtonClick()
              }}
            >
              {'Lookup address'}
            </OutlinedButton>
          </Container>

          {addressOptions.length > 0 && addressOptions && (
            <SelectField
              name={'postcode-lookup-dropdown'}
              label={'Select address'}
              options={addressOptions}
              error={error}
              onChange={handleDropDownChange}
              value={value}
              placeholder={'Please select address'}
            />
          )}

          {noAddresses && (
            <div className="ln-u-padding-bottom*3">
              <ErrorCircle
                id="s-search-error"
                className="s-error-icon ln-u-display ln-u-margin-bottom*1/2"
              />
              <span className="s-error-address ln-u-padding">
                {'No addresses were found'}
              </span>
            </div>
          )}
        </>
      )}

      {loading && (
        <ProgressIndicator loading preventFocus>
          <ProgressBar
            className="ln-u-push-bottom-lg"
            title="Regular sized progress indicator demo"
          />
        </ProgressIndicator>
      )}

      {manualAddress && (
        <>
          {firstLine && <ComponentSwitch {...firstLine} answer={value.line1} defaultValue={firstLine.defaultValue} key={`${key}-${firstLine.id}`} /> }
          {secondLine && <ComponentSwitch {...secondLine} answer={value.line2} defaultValue={secondLine.defaultValue} key={`${key}-${secondLine.id}`} /> }
          {thirdLine && <ComponentSwitch {...thirdLine} answer={value.line3} defaultValue={thirdLine.defaultValue} key={`${key}-${thirdLine.id}`} /> }
          {city && <ComponentSwitch {...city} answer={value.city} defaultValue={city.defaultValue} key={`${key}-${city.id}`} /> }
          {county && <ComponentSwitch {...county} answer={value.county} defaultValue={county.defaultValue} key={`${key}-${county.id}`} /> }
          {postcode && <ComponentSwitch {...postcode} answer={value.postcode} defaultValue={postcode.defaultValue} key={`${key}-${postcode.id}`} /> }
          {country && <ComponentSwitch {...country} answer={value.country} defaultValue={country.defaultValue} key={`${key}-${country.id}`} /> }
        </>
      )}

      <button
        className={`s-manual-address ln-u-padding-bottom*3`}
        onClick={() => handleManualButton(!manualAddress)}
        type="button"
      >
        {manualAddress ? 'Use postcode lookup' : 'Enter address manually'}
      </button>
    </div>
  )
}

export default PostcodeLookupComponent
